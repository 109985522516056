module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Demo Aroostook","short_name":"Demo Aroostook","start_url":"/","theme_color":"#6b37bf","background_color":"#6b37bf","display":"standalone","icon":"src/images/lobster512.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"5649b3c14d188ffcac993cfd7643111a"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://aroostook.lobstermarketing.com"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/pest-library","crumbLabel":"Pest Library"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-200},
    },{
      plugin: require('../../gatsby-theme-aroostook/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"34963517-cc36-005e-f86a-ece897901dd4","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImM4MTQzNGYyYmI3NDQwNDNiMDdhZjZhZGZhOWMyMGQ4IiwNCiAgImlhdCI6ICIxNjE0MDk1ODYxIiwNCiAgImV4cCI6ICIxOTU5Njk1ODYxIiwNCiAgInByb2plY3RfaWQiOiAiMzQ5NjM1MTdjYzM2MDA1ZWY4NmFlY2U4OTc5MDFkZDQiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.77Fi3sZ1HxnbAniBzaSJNHb_dWKOnOy00X0yos81XiM","usePreviewUrl":"false","whereWeServicePathName":"where-we-service","whereWeServiceBreadcrumb":"Where We Service","wWSActive":"0","siteUrl":"https://aroostook.lobstermarketing.com","title":"Demo Aroostook","description":"FieldRoutes","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","compassID":"42a82a20fd60882a6cf4067db94368738701fce0d5ce61ec6c4845b74d313fbc","recaptchaSiteKey":"6LdG2dohAAAAAFGdtsY1Oz3Fiywctl5qj29Y3EUB","recaptchaSecretKey":"6LdG2dohAAAAACl-tAId5i0s08MIrgCr6LPavlSJ","pestLibraryPathName":"pest-library","pestLibraryBreadcrumb":"Pest Library","pestLibraryActive":"0"},
    }]
